import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import { Loader } from 'legacy';
import { Button, Table } from 'antd';
import { downloadResponse } from '../pages/bookings/booking-parts/download-document-button';
import { DownloadOutlined } from '@ant-design/icons';
import useScrollRestoration from './use-scroll-restoration';

const PAGE_SIZE = 10;

const TablePages = ({
    columns,
    serializeRequest,
    serializeFilename,
    formValues,
    route,
    downloadRoute,
    rowKey = 'id',
    page,
    setPage,
    pageSize = PAGE_SIZE,
    handleRequestError,
    ...props
}) => {
    const [list, setList] = useState(null);
    const [total, setTotal] = useState(0);
    const [isDownloading, setDownloading] = useState(false);

    useScrollRestoration(!list);

    const loadPage = () => {
        setList(null);
        if (formValues) {
            // temporary eq(htId) workaround until mapper method emulates odata // todo
            API[rowKey === 'htId' ? 'post' : 'get']({
                ...route,
                body: serializeRequest(formValues, pageSize, (page - 1) * pageSize),
                headers: ['count'],
                success: ([result, count]) => {
                    if (rowKey === 'htId') {
                        setList(result.slimAccommodationData);
                        setTotal(parseInt(result.totalNumberOfItems));
                    } else {
                        setList(result);
                        setTotal(parseInt(count));
                    }
                },
                error: handleRequestError,
            });
        }
    };

    const downloadAll = () => {
        setDownloading(true);
        API.get({
            ...downloadRoute,
            body: serializeRequest(formValues),
            method: 'POST',
            response: (res) => {
                downloadResponse(res, `${serializeFilename(formValues)}.csv`, 'Unable to download bookings');
                setDownloading(false);
            },
            error: () => {
                setDownloading(false);
            },
        });
    };

    useEffect(() => {
        loadPage();
    }, [formValues, page]);

    return (
        <>
            {downloadRoute && total && total <= 50000 ? (
                <div
                    style={{
                        marginBottom: '-50px',
                        zIndex: '2',
                        position: 'relative',
                        width: '200px',
                    }}
                >
                    <Button disabled={isDownloading} onClick={downloadAll} icon={<DownloadOutlined />}>
                        Download Bookings
                    </Button>
                </div>
            ) : null}
            <Table
                dataSource={list}
                columns={
                    list?.length
                        ? columns({ page }).map((column) => ({
                              title: column.header,
                              ...(typeof column.cell === 'string'
                                  ? { dataIndex: column.cell }
                                  : { render: (text, record) => column.cell(record) }),
                              key: typeof column.cell === 'string' ? column.cell : column.title,
                          }))
                        : null
                }
                pagination={{
                    current: page,
                    total,
                    showQuickJumper: false,
                    showSizeChanger: false,
                    pageSize: pageSize,
                    onChange: setPage,
                    showTotal: (total, range) => (
                        <div style={{ marginRight: 20 }}>
                            {`${range[0]}-${range[1]}`} of <b>{total} items</b>
                        </div>
                    ),
                    position: ['topRight'],
                }}
                loading={list === null}
                rowKey={rowKey}
                locale={{
                    emptyText: list === null ? <Loader /> : 'Nothing to Show',
                }}
                style={{ minHeight: 1000, marginTop: list?.length === 0 ? 25 : 0 }}
                {...props}
            />
        </>
    );
};

export default TablePages;
