import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Alert, Form, Table } from 'antd';
import usePages from 'components/use-pages';
import TablePages from 'components/table-pages';
import { agentColumns, columns } from './api-table-settings';
import { serializeRequest } from './serializer';
import TableSearchForm from './table-search-form';

const ExpandedRowRender = ({ list, agencyId }) => (
    <Table pagination={false} rowKey="id" dataSource={list} columns={agentColumns(agencyId)} />
);

const OpenedAgenciesApi = () => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [page, setPage] = usePages();

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const { code } = useParams();

    useEffect(() => {
        form.validateFields().then(setFormValues);
    }, []);

    const onExpand = (id) =>
        setExpandedRowKeys((prevState) =>
            prevState.includes(id) ? prevState.filter((item) => item !== id) : [...prevState, id]
        );

    const onSubmit = (values) => {
        setErrorMessage(null);
        setPage(1);
        setFormValues(values);
    };

    const handleRequestError = ([error]) => setErrorMessage(error.detail);

    return (
        <>
            <TableSearchForm searchForm={form} onSubmit={onSubmit} />

            {errorMessage ? (
                <Alert
                    description={errorMessage}
                    type="warning"
                    showIcon
                    style={{
                        margin: '0 20px 20px 0',
                        padding: 18,
                        borderColor: '#d9d9d9',
                        backgroundColor: '#d9d9d915',
                    }}
                />
            ) : (
                <TablePages
                    className="nested-table"
                    columns={() => columns(expandedRowKeys, onExpand)}
                    formValues={formValues}
                    route={{ url: apiMethods.openedAgenciesApi(code) }}
                    serializeRequest={(...rest) => serializeRequest(...rest, 'QPS desc')}
                    page={page}
                    setPage={setPage}
                    handleRequestError={handleRequestError}
                    expandable={{
                        expandRowByClick: true,
                        expandedRowRender: (row) => <ExpandedRowRender list={row.agents} agencyId={row.id} />,
                        expandIcon: () => <></>,
                        expandedRowKeys,
                        expandedRowClassName: () => 'child-table',
                    }}
                />
            )}
        </>
    );
};

export default OpenedAgenciesApi;
