import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollRestoration = (isLoading) => {
    const location = useLocation();

    const [isFirstLoading, setIsFirstLoading] = useState(true);

    useEffect(() => {
        const savedPosition = sessionStorage.getItem(location.pathname);

        if (!isLoading && isFirstLoading) {
            window.scrollTo(0, parseInt(savedPosition || 0));
            setIsFirstLoading(false);
        }
    }, [location.pathname, isLoading, isFirstLoading]);

    const handleScroll = () => {
        if (!isFirstLoading) {
            sessionStorage.setItem(location.pathname, window.scrollY);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isFirstLoading]);
};

export default useScrollRestoration;
